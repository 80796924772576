import { graphql, Link } from "gatsby"
import React from "react"
import useArticlePath from "../../../hooks/useArticlePath"
import { slugify } from "../../../utils"
import Button from "../../atoms/Button"
import { HarviaBackgroundColor } from "../../layouts/BgColor"
import BackgroundMedia from "../../molecules/BackgroundMedia"
import MediaTextBlock, { MediaTextBlockLayout } from "../../molecules/tileable-blocks/MediaTextBlock"
//import * as styles from "./DatoCtaToArticle.module.scss"

const DatoCtaToArticle = (props: Queries.DatoCtaToArticleFragment) => {
  //console.log('Rendering DatoCtaToArticle', props)
  const getArticlePath = useArticlePath()
  if (!props.article) return null
  const path = getArticlePath(props.article.originalId)
  return (
    <MediaTextBlock
      id={slugify(props.article.title)}
      layout={props.layout as MediaTextBlockLayout}
      contentBackground={props.textBackground as HarviaBackgroundColor}
      media={<BackgroundMedia {...props.article.mainImage} />}
      content={
        <>
          <p style={{ marginBottom: 0 }}>{props.article.articleCategory?.name}</p>
          <h3 style={{ marginTop: 0 }}>{props.article.title}</h3>
          <p style={{ flexGrow: 1 }}>{props.article.excerpt}</p>
          {path && (
            <Link to={path as string}>
              <Button label={props.linkText} tabIndex={-1} />
            </Link>
          )}
        </>
      }
    />
  )
}

export default DatoCtaToArticle

export const query = graphql`
  fragment DatoCtaToArticle on DatoCmsCtaToArticle {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    linkText
    layout
    textBackground
    article {
      originalId
      slug
      mainImage {
        ...DatoImageDefault
      }
      title
      excerpt
      articleCategory {
        name
        slug
      }
      articleTags {
        label
      }
    }
  }
`
